import axios from 'axios'
import ApiConfig from 'config/apiConfig'
import { createBrowserHistory } from 'history'
import { store } from 'redux/store'
import Routes from 'constants/routes'
import reactLocalStorage from 'utils/localStorage'

const apiConfig = ApiConfig()

const instance = () => {
  const history = createBrowserHistory()
  const { accessToken } = store.getState().user

  const headers: any = {
    'Content-Type': 'application/json',
  }
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const axiosInstance = axios.create({
    baseURL: apiConfig.BASE_URL,
    headers,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.data) {
        const haveFile = Object.values(config.data).some((e: any) => e && e.toString() === '[object File]')
        if (haveFile) {
          // eslint-disable-next-line no-param-reassign
          config.headers['Content-Type'] = 'multipart/form-data'
        }
      }

      return config
    },
    (error) => {
     return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (error.response && error.response.data) {
        if (error?.response?.data?.error === 'invalid_token' || error?.response?.data === 'Forbidden') {
          reactLocalStorage.clear()
          history.push(Routes.LOGIN)
          window.location.reload()
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...error.response.data })
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          error: true,
          message: 'Error Code 100: No response error from server',
          statusCode: error && error.request && error.request.status ? error.request.status : '1899',
        })
      }
    },
  )
  return axiosInstance
}

export default instance
